import { usePathname } from 'next/navigation';
import cx from 'classnames';
import { ReactNode } from 'react';
import { FloatingPosition, Tooltip } from '@mantine/core';
import { UserType } from '@/types/user';
import Image from 'next/image';
import { ArrowUUpLeft } from 'phosphor-react';
import { useRouter } from 'next/router';
import { Button } from 'andromeda';
import { useUserAccess } from '@/hooks/useUserAccess';
import ChatIcon from '../../andromeda/Orby';

// Explicit types for direction and position
export type Position = 'top' | 'bottom' | 'left' | 'right';
export type Direction = 'horizontal' | 'vertical';

interface NavigationItemProps {
    Icon: (active: boolean) => ReactNode;
    label: string;
    link: string;
    position?: FloatingPosition;
    disabled?: boolean;
    isChat?: boolean;
}

export interface NavigationBadgeProps {
    direction?: Direction;
    position?: Position;
    items: readonly NavigationItemProps[];
    showChat?: boolean;
    className?: string;
    containerClassName?: string;
    user: UserType;
    padding?: string;
}

const RouteBackButton = () => {
    const router = useRouter();

    const handleBack = () => {
        router.back();
    };

    return <Button onClick={handleBack} aria-label="Go back" leftSection={<ArrowUUpLeft />} />;
};

const NavigationItem = ({
    disabled,
    Icon,
    link,
    label,
    position,
    isChat = false,
}: NavigationItemProps) => {
    const router = useRouter();

    const isRouteActive = router.pathname === link;

    const handleRoute = () => {
        router.push(link);
    };

    return (
        <Tooltip label={label} position={position} withArrow>
            <button
                type="button"
                onClick={handleRoute}
                className={cx(
                    'p-2 flex flex-col items-center rounded-full font-light text-neutral-900 dark:text-columbia-blue-light hover:bg-columbia-blue-light',
                    {
                        '!bg-primary text-secondary dark:text-primary !cursor-default':
                            !isChat && isRouteActive,
                        'hover:bg-columbia-blue-light': !isChat && !isRouteActive && disabled,
                        'bg-secondary': isChat && isRouteActive && disabled,
                        'hover:!bg-columbia-blue-dark': isChat,
                        'hover:cursor-not-allowed opacity-30 !bg-transparent': disabled,
                    },
                )}
                disabled={isRouteActive || disabled}
            >
                {Icon(isRouteActive)}
            </button>
        </Tooltip>
    );
};

export const Navigation = ({
    direction = 'vertical',
    items,
    showChat = true,
    containerClassName,
    user,
    padding = 'p-4',
}: NavigationBadgeProps) => {
    const pathName = usePathname();
    const isArenaStaff = useUserAccess({ userGroupWhitelist: ['arena-staff'] });

    // Memoize tooltip position calculation
    const tooltipPosition = direction === 'horizontal' ? 'bottom' : 'right';
    const chatIcon = active => <ChatIcon size={20} />;

    return (
        <div
            className={cx(
                'fixed left-0 top-0 h-screen w-auto flex flex-col justify-between overflow-hidden', // Ensure the container doesn't exceed viewport height
                padding, // Apply padding for spacing from the edges
                containerClassName,
            )}
        >
            {pathName !== '/' ? <RouteBackButton /> : <div className="size-8" />}

            <div className="flex flex-col items-center rounded-full bg-columbia-blue">
                <div
                    className={cx(
                        'flex items-center p-1 h-fit rounded-full bg-columbia-blue-lighter shadow-sm border border-columbia-blue-dark',
                        {
                            'flex-col space-y-1': direction === 'vertical',
                            'flex-row space-x-1': direction === 'horizontal',
                        },
                    )}
                >
                    {items.map(item => (
                        <NavigationItem key={item.label} {...item} position={tooltipPosition} />
                    ))}
                </div>
                {/* If client want to see chat button but disabled for now remove `isArenaStaff` */}
                {showChat && (
                    <div className="flex p-1">
                        <div
                            className={cx(
                                'flex p-0 hover:cursor-pointer flex-col items-center rounded-full',
                                {
                                    'bg-gray-dark': pathName === '/chat',
                                    'bg-transparent hover:bg-gray-dark': pathName !== '/chat',
                                },
                            )}
                        >
                            <NavigationItem
                                Icon={chatIcon}
                                label="Chat"
                                link="/chat"
                                isChat
                                position={tooltipPosition}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className="flex">
                <div className="group flex size-9 items-center justify-center rounded-full border border-l-columbia-blue-darker bg-primary hover:cursor-pointer hover:border-columbia-blue-darker hover:shadow-md">
                    {user?.thumbnail ? (
                        <Image
                            src={user.thumbnail}
                            alt={`${user.firstName} ${user.lastName}`}
                            className="flex size-6 rounded-full p-1"
                        />
                    ) : (
                        <div className="flex size-6 rounded-full bg-pacific p-1 transition-all group-hover:size-[26px]" />
                    )}
                </div>
            </div>
        </div>
    );
};
