import { Image } from '@mantine/core';

const ChatIcon = ({ className, size = 24 }: { className?: string; size?: number }) => (
    <Image
        src="/images/orby_chat_colored.svg"
        width={size}
        height={size}
        alt="orby-loader"
        className={className}
        styles={{
            root: {
                display: 'inline',
                width: `${size}px`,
            },
        }}
    />
);

export default ChatIcon;
