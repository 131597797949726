import { Button } from '@mantine/core';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React from 'react';

const TabButton = React.forwardRef(
    (
        {
            leftIcon,
            onClick,
            disabled,
            children,
            isActive,
            id,
            width = 'fit-content',
            className,
            layoutId,
        }: {
            onClick: () => void;
            leftIcon?: JSX.Element;
            disabled?: boolean;
            children: string | JSX.Element;
            className?: string;
            isActive: boolean;
            id: string;
            layoutId: string;
            width?: number | string;
        },
        ref: React.Ref<HTMLButtonElement>,
    ) => (
        <div className="relative flex">
            <Button
                id={id}
                ref={ref}
                style={{
                    width,
                    height: '32px',
                }}
                variant="subtle"
                className={classNames(
                    `relative pl-3.5 pr-4 z-10 rounded-lg text-sm text-columbia-blue-v2-400 cursor-pointer hover:text-secondary font-bold capitalize`,
                    {
                        '!text-secondary': isActive,
                        '!text-navy-30': disabled,
                        '!cursor-default': disabled || isActive,
                    },
                    className,
                )}
                leftSection={leftIcon}
                classNames={{
                    loader: 'hidden',
                    inner: ' flex items-center justify-center gap-1.5',
                    section: isActive && '!text-primaryBlue',
                }}
                onClick={onClick}
                disabled={disabled || isActive}
                type="button"
                loading={false}
                unstyled
            >
                {children}
            </Button>
            {isActive ? (
                <motion.div
                    className="absolute z-0 size-full rounded-lg border-0.5 border-columbia-blue-v2-300 bg-columbia-blue-light"
                    layoutId={layoutId}
                />
            ) : null}
        </div>
    ),
);

export default TabButton;
