import React, { useEffect, useMemo, useState } from 'react';

type TabValue<Tabs> = {
    // ? you don't need [[[label]]] if its equal to [[[value]]]
    label?: string | JSX.Element;
    value: Tabs;
    component: JSX.Element;
    icon?: JSX.Element;
    className?: string;
    disabled?: boolean;
}[];

type TabContextProps<Tabs extends string> = {
    values: TabValue<Tabs>;
    onChange?: (tab: Tabs) => void;
    selectedTab: string;
    defaultTab?: Tabs;
    value?: Tabs;
    setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
};

type StyledTabProps<Tabs extends string> = Omit<
    TabContextProps<Tabs>,
    'selectedTab' | 'setSelectedTab'
>;
const TabContext = React.createContext<TabContextProps<string>>({
    values: [],
    onChange: () => {},
    setSelectedTab: () => ({}),
    selectedTab: '',
});

function TabWrapper<Tabs extends string>({
    children,
    values = [],
    onChange,
    value,
    defaultTab,
}: { children: JSX.Element[] } & Omit<TabContextProps<Tabs>, 'selectedTab' | 'setSelectedTab'>) {
    const [selectedTab, setSelectedTab] = useState<Tabs>(value ?? defaultTab ?? values?.[0]?.value);

    useEffect(() => {
        if (!value) return;
        if (value !== selectedTab) {
            setSelectedTab(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const contextValue = useMemo<TabContextProps<Tabs>>(
        () => ({ values, onChange, selectedTab, setSelectedTab }),
        [onChange, selectedTab, values],
    );
    return (
        <TabContext.Provider value={contextValue}>
            <div className="flex flex-col">{children}</div>
        </TabContext.Provider>
    );
}

const useTabContext = () => {
    const props = React.useContext(TabContext);
    if (!props) {
        throw new Error('StyledTabs needs to be used inside <Tab>{...children}</Tab>');
    }
    return props;
};

export default TabWrapper;
export { useTabContext };
export type { StyledTabProps, TabValue };
