export const driverBreakdown = {
    'Sales Performance': {
        'Sales Growth (YoY)':
            'YoY Change in Sales in the past period. Past period is defined based on the date: (1) Last complete month and (2) MTD after day 20 of the current month',
        'Progress vs Target (Sales)': 'Progress against the monthly S&OP target',
    },

    'Sales Construct': {
        '# Outlets (ECO)': 'Number of stores with at least 1 transaction in the past period',
        ECO: 'Number of stores with at least 1 transaction in the past period',
        'Bills / Outlet': 'Number of transactions in the past period',
        'Bills/Outlet': 'Number of transactions in the past period',
        'Average Bill Value': 'Average Sales per transaction in the past period',
        ABV: 'Average Sales per transaction in the past period',
        Sales: 'Progress against the monthly S&OP target',
    },

    Calendar: {
        'Sales Impact': 'Impact of this driver on the overall sales performance',
        Workdays: 'No of working days in the Past Period',
        'School closure': 'No of days that the school is closed in the last period',
    },

    Weather: {
        'Sales Impact': 'Impact of this driver on the overall sales performance',
        'Hot Days': 'Number of days when the average feels like temperature is higher than 36.5C',
        'Rainy Days': 'Number of days with precipitation',
        'Heavy Rainfall Days': 'Number of days with large rainfall',
    },

    Visits: {
        'Sales Impact': 'Impact of this driver on the overall sales performance',
        'Avg Visits': 'Daily Avg Visits per Sales Rep in the past period',
        'PJP Visit Compliance':
            '% of stores visited according to the daily planned routes in past period',
        'Perfect Store Compliance':
            '% of stores with full perfect store compliance (6 out of 6 criteria) in past period',
    },

    'Store Performance': {
        'Sales Impact': 'Impact of this driver on the overall sales performance',
        'New stores': 'Number of stores with their first transaction in the last period',
        'Existing stores': 'Number of stores with transactions in the last 90 days',
        'Churned stores':
            'Number of stores with no transactions in the last 90 days (3 months) and had transactions in the prior 270 days (9 months)',
    },

    'Competitive Heat': {
        'Sales Impact': 'Impact of this driver on the overall sales performance',
        'Basket Utilization':
            'Average Number of Baskets with Unilever Products / Total Baskets (7Eleven only)',
        'Sales Market Share':
            'Sales Brand Market Share of Unilever in Ice Cream Category in the past period',
        'Volume Market Share':
            'Volume Brand Market Share of Unilever in Ice Cream Category in the past period',
        '% of High TDP SKUs':
            'Number of UL SKUs / Total SKUs, only applicable for SKUs with > 5k Total Distribution Points',
    },

    'Market Growth': {
        'Sales market growth': 'Sales increase from last year for ice cream category',
        'Volume market growth': 'Volume increase from last year for ice cream category',
        'Sales Impact': 'Impact of this driver on the overall sales performance',
    },

    'Innovation Success': {
        'Sales Impact': 'Impact of this driver on the overall sales performance',
        'Avg NPD Volume Per Account': 'Average NPD Volume sold per store in the past period',
        'GSV of NPD Per Account': 'Average NPD Sales per store in the past period',
        'NPD Active Stores': 'Number of stores with at least 1 transaction in the past period',
        'NPD Volume Progress, Monthly':
            'Avg NPD volume sold / Business Case Volume monthly; for the first 6 months',
        'NPD Volume Progress, Cumulative':
            'Avg NPD volume sold / Business Case Volume cumulative; for the first 6 months',
        'NPD Active Store Progress, Monthly':
            'Avg NPD stores / Business Case active stores monthly; for the first 6 months',
        'Net GSV of NPD': 'Avg of NPD - Average Sales of Delisted SKUs',
    },

    Promotions: {
        'Sales Impact': 'Impact of this driver on the overall sales performance',
        'Unilever Promo Slots': 'Number of UL promotion slots in the past period',
        'Volume on Promotion': 'Average volume of promotion SKUs in the past period',
    },

    Pricing: {
        'Average Price Movement': 'Avg Unilever price compared to Avg Ice Cream Category price',
        'Sales Impact': 'Impact of this driver on the overall sales performance',
        'Price Mix': 'Weighted average price of the assortment (Total sales / Total quantity)',
        'Price Change':
            'Average change in price per product compared to last year, only applicable for top 10 SKUs by Sales',
    },

    OOS: {
        'SKUs with <70% CCFOT':
            'SKUs with less than 70% CCFOT, only applicable for top 10 SKUs by Sales',
        CCFOT: 'Weighted average CCFOT value per SKU in the last period (Sum of delivery volume / Sum of order volume)',
        'Sales Impact': 'Impact of this driver on the overall sales performance',
    },
};
