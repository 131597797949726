import React from 'react';

interface CircleProps {
    color: string;
    size?: number;
    className?: string;
    opacity?: number;
}

export const Circle: React.FC<CircleProps> = ({ color, size = 10, className, opacity }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 6 6"
        fill="none"
        opacity={opacity}
    >
        <circle cx="3" cy="3" r="3" fill={color} fillOpacity="0.5" />
        <circle cx="3" cy="3" r="1.5" fill={color} />
    </svg>
);
