import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from '../contexts/appContext';

export interface UseApiQueryParams<Body = any> {
    url: string;
    headers?: { [key: string]: string };
    searchParams?: URLSearchParams;
    queryKeys?: string[];
    disabled?: boolean;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: Body;
    getBlob?: boolean;
    refetchInterval?: number;
}

const useApiQuery = <T, Body = any>({
    url,
    headers,
    searchParams,
    queryKeys = [],
    disabled,
    method = 'GET',
    body,
    getBlob,
    refetchInterval,
}: UseApiQueryParams<Body>): UseQueryResult<T, Error> & { queryKey: string[] } => {
    const { user } = useAppContext();
    const queryKey = useMemo(() => {
        const innerKey = [url, method, ...queryKeys];
        if (searchParams) {
            innerKey.push(searchParams.toString());
        }
        if (body) {
            innerKey.push(JSON.stringify(body));
        }
        return innerKey;
    }, [body, method, queryKeys, searchParams, url]);

    const filteredBody = useMemo(
        () =>
            Object.entries(body ?? {}).reduce(
                (acc, [k, v]) => (typeof v === 'undefined' ? acc : { ...acc, [k]: v }),
                {},
            ),
        [body],
    );

    const parseSafeJSON = jso => {
        const safeText = jso.replace(/NaN/g, 'null');
        return JSON.parse(safeText);
    };

    const configQuery = useQuery<T, Error>({
        queryKey,
        queryFn: async ({ signal }) => {
            const apiUrl = new URL(url);
            if (searchParams) {
                apiUrl.search = searchParams.toString();
            }

            const response = await fetch(apiUrl.toString(), {
                method,
                headers: headers
                    ? { ...headers }
                    : {
                          accept: 'application/json',
                          authorization: `Bearer ${user.siberiaToken}`,
                      },
                signal,
                body: body ? JSON.stringify(filteredBody) : undefined,
            });

            if (!response.ok) {
                // Handling HTML Response
                const res = await response
                    .clone()
                    .text()
                    .catch(() => {
                        throw new Error('Failed to parse response');
                    });
                const res2 = await response
                    .clone()
                    .json()
                    .catch(() => {
                        throw new Error('Failed to parse response');
                    });

                return Promise.reject(
                    Object.keys(res2).length > 0
                        ? { ...res2, message: res2?.message ?? res2?.detail }
                        : res,
                );
            }

            if (getBlob) {
                return response.blob().catch(() => {
                    throw new Error('Failed to parse response');
                });
            }

            const data = await response
                .text()
                // .json()
                .then(jso => parseSafeJSON(jso))
                .catch(() => {
                    throw new Error('Failed to parse response');
                });

            return data;
        },
        refetchOnWindowFocus: false,
        refetchInterval: refetchInterval || false,
        enabled: !disabled,
        retry: false,
        retryDelay: 100,
    });

    return useMemo(() => ({ ...configQuery, queryKey }), [configQuery, queryKey]);
};

export default useApiQuery;
