import { AdditionalDataItem } from '../StorePanel/OverviewPanel/mock';
import { Brand } from '../useGetBrands';
import { Category } from '../useGetCategories';
import { Cluster } from '../useGetClusters';
import { Route } from '../useGetRoutes';

export type TableTab = 'route' | 'cluster' | 'supervisor' | 'brand' | 'category';

export type State = {
    hasNewMessages: boolean;
    hoveredCluster?: Cluster & AdditionalDataItem;
    hoveredOutlet?: string;
    hoveredRoute?: Route;
    hoveredBrand?: Brand;
    hoveredCategory?: Category;
    hoveredSupervisor?: Route;
    selectedProduct?: string;
    selectedOverviewTable: TableTab;
    insightDetails?: boolean;
};

export const initialState: State = {
    hasNewMessages: false,
    hoveredCluster: undefined,
    hoveredOutlet: undefined,
    hoveredRoute: undefined,
    selectedProduct: undefined,
    selectedOverviewTable: 'supervisor',
    insightDetails: false,
};

export const createMethods = (state: State) => ({
    setHasNewMessages: (hasNewMessages: boolean) => ({
        ...state,
        hasNewMessages,
    }),
    setHoveredCluster: (cluster?: Cluster & AdditionalDataItem) => ({
        ...state,
        hoveredCluster: cluster,
    }),
    setHoveredOutlet: (outlet?: string) => ({
        ...state,
        hoveredOutlet: outlet,
    }),
    setHoveredRoute: (route?: Route) => ({
        ...state,
        hoveredRoute: route,
    }),
    setHoveredBrand: (brand?: Brand) => ({
        ...state,
        hoveredBrand: brand,
    }),
    setHoveredCategory: (category?: Category) => ({
        ...state,
        hoveredCategory: category,
    }),
    setSelectedProduct: (product?: string) => ({
        ...state,
        selectedProduct: product,
    }),
    setSelectedOverviewTable: (table: TableTab) => ({
        ...state,
        selectedOverviewTable: table,
    }),
    setHoveredSupervisor: (supervisor?: Route) => ({
        ...state,
        hoveredSupervisor: supervisor,
    }),
    setInsightDetails: (insightDetails: boolean) => ({
        ...state,
        insightDetails,
    }),
});

export type Methods = ReturnType<typeof createMethods>;
